@use 'common'

.imageLinkListWrapper
	margin: 2em 0
	background: white
	padding: 4rem 0
	@media (min-width: common.$break48)
		padding: 8rem 0

.imageLinkListTitle
	font-size: 2em
	margin: 0.5em 0 3rem

.imageLinkListBox
	display: flex
	align-items: center
	max-width: 1200px
	margin-block: 2rem
	margin-inline: auto
	flex-wrap: wrap
	gap: 2em
	@media (min-width: common.$break48)
		gap: 1em

.imageLinkListItem
	display: flex
	align-items: center
	margin: 0.25em auto
	max-width: 40%
	width: 100%
	justify-content: center

	@media (min-width: common.$break48)
		max-width: 22%
		margin: 1em auto

.image
	object-fit: cover
	max-width: 140px
	@media (min-width: common.$break48)
		max-width: 190px
		width: 100%
