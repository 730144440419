@use 'common'
@use 'sass:math'

.Downloads
	display: flex
	flex-direction: column
	background-color: #ffffff
	margin: 2rem 0
	border: 1px solid #F0F0F0
	padding: 1.5rem
	@media (min-width: common.$break48)
		padding: 0 4rem 4rem

		&[data-files-count="1"],
		&[data-files-count="2"]
			width: math.div(100%, 3)

		&[data-files-count="3"],
		&[data-files-count="4"]
			width: calc((100% / 3) * 2)

.Title
	+common.subtitle
	color: common.$grey

.Files
	display: flex
	flex-wrap: wrap
	@media (min-width: common.$break48)
		column-count: 3
		display: block

		&[data-files-count="1"],
		&[data-files-count="2"]
			column-count: 1

		&[data-files-count="3"],
		&[data-files-count="4"]
			column-count: 2

.File
	display: flex
	align-items: flex-start
	width: 100%
	padding: 0.5rem

	@media (min-width: common.$break30)
		width: calc(50% - 0.5rem)

	@media (min-width: common.$break48)
		width: fit-content

.FileImage
	font-size: 4.375rem

.FileNameBox
	margin-left: 16px

.FileName
	+common.text
	margin: 0
	font-weight: 700

.Button
	+common.text
