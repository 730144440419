@use 'common'

.Program
	display: flex
	flex-direction: column

.Images
	display: flex
	@media not all and (min-width: common.$break48)
		margin-top: 3rem

.Image
	width: 50%
	@media (min-width: common.$break48)
		margin-top: 6.25rem

.Ilustration
	display: flex
	justify-content: flex-start
	align-items: center
	margin-top: -3rem
	@media (min-width: common.$break75)
		align-items: flex-start
		margin-top: 6rem

.Photo
	position: relative
	&::before
		content: ""
		display: block
		background: linear-gradient(358.7deg, #FBF9F9 1.19%, rgba(255, 255, 255, 0) 68.71%)
		position: absolute
		inset: 0
		z-index: 1

.Title
	+common.title
	position: relative
	max-width: 63rem
	z-index: 2
	@media (min-width: common.$break48)
		margin-top: -6.25rem

.LeadParagraph
	+common.text
	max-width: 608px
	font-weight: 700

.Content
	margin-top: 2rem
	@media (min-width: common.$break48)
		margin-top: 4rem
	// @media (min-width: common.$break75)
	// 	margin-top: 12.5rem
