@use 'common'

.newsAndStoriesTile
	width: 100%
	margin-bottom: 1rem
	@media (min-width: common.$break48)
		width: calc(50% - 0.625rem)
	@media (min-width: common.$break62)
		width: calc(50% - 1.25rem)

.heading
	font-size: 2em
	margin: 0.5em 0 0.2em

	&:hover
		color: common.$red

.perex
	//
	
.image
	display: block
	width: 100%
	height: 400px
	object-fit: cover
