@use 'common'

.Header
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: 1.5rem

.Logos
	display: flex

.Links
	display: none
	z-index: 2
	flex-direction: column
	@media (min-width: common.$break62)
		display: flex
		flex-direction: row

.Link
	+common.text
	margin-right: 2rem
	font-weight: 700
	&:hover
		color: common.$red
	@media not all and (min-width: common.$break48)
		font-size: 1.25rem
		line-height: 1.875rem
	&Highlighted
		color: common.$red

.MenuBox
	position: relative
	&:hover
		.Submenu
			display: flex
			visibility: initial
			opacity: 1

.SubmenuWrapper
	position: absolute
	min-width: 15.625rem
	left: 50%
	transform: translateX(-50%)

.LastItem
	right: 0
	left: auto
	transform: translateX(0)

.Submenu
	display: none
	visibility: hidden
	opacity: 0
	flex-direction: column
	padding: 1rem
	border-radius: 10px
	background-color: #fff
	box-shadow: 0 .5625rem 1.5rem rgba(0, 0, 0, 0.15)
	margin-top: 1rem

.SubmenuLink
	font-size: .8125rem
	margin-right: 2rem
	@media (min-width: common.$break62)
		font-size: 1.25rem
		line-height: 1.875rem
		padding: 0.25rem

	&:hover
		color: common.$red

.isActiveLink
	color: common.$red
	@media not all and (min-width: common.$break62)
		text-decoration: underline

.HeaderMobile
	@media (min-width: common.$break62)
		display: none

.IconHamburger
	display: flex
	border: none
	background-color: transparent
	font-size: 1.75rem
	@media (min-width: common.$break62)
		display: none

.Close
	position: absolute
	width: 100%
	display: flex
	justify-content: flex-end
	padding-right: 2.5rem
	padding-top: 0.5rem

.MobileHeader
	display: none
	flex-direction: column
	position: absolute
	inset: 0
	z-index: 2
	background-color: common.$background-color
	margin-top: 1.5rem

.MobileLinks
	margin-top: 2rem
	color: common.$red

.MobileLink
	+common.title
	margin-bottom: 1rem

.isActive
	display: flex
	.Link
		display: flex
