@use 'common'
@use 'sass:math'

.Boxes
	display: flex
	flex-wrap: wrap
	margin: 3.5rem 0
	@media (min-width: common.$break48)
		margin: 3.5rem 0

.BoxWrapper
	width: 100%
	margin-bottom: 2rem
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break62)
		width: 25%

.Box
	display: flex
	flex-direction: column
	justify-content: space-between
	background-color: #ffffff
	margin-bottom: 2.1875rem
	height: 100%
	padding: 2rem 2rem 1.5rem

	@media (min-width: common.$break30)
		max-width: 12.5rem
	@media (min-width: common.$break48)
		max-width: 14.5rem

	@media (min-width: common.$break75)
		padding: 3rem 3rem 1.5rem
		max-width: 17rem
	@media (min-width: 81.25rem)
		max-width: 18.75rem

.Title
	+common.subtitle
	margin: 0 0 1.5rem
	color: common.$grey

.Text
	+common.text
	margin-top: .625rem

.Link
	+common.text
