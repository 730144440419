@use 'common'

.title
	font-size: 1.875rem
	line-height: 3rem
	font-weight: 700
	margin-bottom: 1.5rem

	@media (min-width: common.$break48)
		font-size: 3rem
		line-height: 3.5rem
		margin-bottom: 2.5rem

.itemsBox
	display: flex
	flex-direction: column
	justify-content: space-between
	margin-top: 20px
	gap: 1rem

	@media (min-width: common.$break48)
		flex-direction: row
		gap: 1.25rem

.button
	font-size: 1.875rem
	line-height: 2.25rem
	color: common.$red
	font-weight: 700
	margin-bottom: 4.5rem
