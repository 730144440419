@use 'common'
@use 'sass:math'

.Jumbotron
	background-color: #ffffff
	padding: 2rem 0 4rem
	margin: 5rem 0
	@media (min-width: common.$break62)
		padding: 2rem 0 8.5rem
	@media (min-width: common.$break75)
		padding: 2rem 0 12.5rem

.Container
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: space-between
	@media (min-width: common.$break62)
		flex-direction: row
		align-items: flex-end

.TextBox
	width: 100%

.Title
	+common.title
	max-width: 68rem

.Subtitle
	+common.text
	font-weight: 700
	max-width: 35rem

.SubtitleWide
	max-width: 48rem

.Box
	display: flex
	align-items: flex-end

.ContentBox
	width: 80%

.Line
	display: none
	@media (min-width: common.$break62)
		display: block
		font-size: 2.5625rem
	@media (min-width: common.$break75)
		font-size: 3.8125rem

.ButtonWrapper
	display: flex
	flex-direction: column
	align-items: center
	margin-top: 2rem
	@media (min-width: common.$break62)
		margin-bottom: -3rem

.Heart
	display: flex
	margin-bottom: 2rem
	font-size: 11.5625rem

.Button
	+common.subtitle
	white-space: nowrap
