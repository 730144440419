@use 'common'
@use 'sass:math'

.Steps
	display: flex
	flex-wrap: wrap
	margin: 3.5rem 0
	@media (min-width: common.$break48)
		margin: 3.5rem 0

.StepWrapper
	width: 100%
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break62)
		width: 25%

.TwoSteps
	width: 100%
	@media (min-width: common.$break48)
		width: 50%

.Numbered
	width: 100%
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break75)
		width: 25%

.Step
	margin-bottom: 2.1875rem

	@media (min-width: common.$break30)
		margin-right: 2.5rem
	@media (min-width: common.$break75)
		margin-right: 5rem

.StepNumbered
	.Title
		display: flex
	.Text
		padding-right: 0
		margin-top: 0
		margin-left: 3.5rem

.Title
	+common.text
	display: flex
	margin: 0
	padding-right: 1.25rem
	b
		+common.subtitle
	em
		font-style: normal

.Number
	background-color: #000000
	border-radius: 50%
	color: #ffffff
	margin-right: 0.5rem
	height: 3rem
	padding: 1rem
	aspect-ratio: 1 / 1
	display: flex
	align-items: center
	justify-content: center

.Text
	+common.text
	padding-right: 1rem
	margin-top: .625rem

	em
		+common.subtitle
		font-style: normal
		color: common.$red
