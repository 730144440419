@use 'common'
@use 'sass:math'

.StaffList
	display: flex
	flex-wrap: wrap

.StaffWrapper
	width: 100%
	// margin-top: 4rem
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break62)
		width: 25%

.Staff
	max-width: 13rem
	margin-bottom: 2.1875rem
	margin-bottom: 6rem
	@media (min-width: common.$break75)
		max-width: 16rem

.Name,
.Position
	+common.text
	margin: 0.5rem 0
	font-weight: 700

.Info
	font-weight: 400
	font-size: .75rem
	line-height: 1.125rem
	@media (min-width: common.$break48)
		font-size: 1rem
		line-height: 1.375rem

.Name
	color: common.$red
