@use 'common'

.Footer
	display: flex
	flex-direction: column

.Columns
	@media (min-width: common.$break62)
		display: grid
		gap: 2em
		grid-template-columns: 1fr 2fr 2fr
	// @media (min-width: common.$break48)
	// 	flex-direction: row

.Column
	display: flex
	flex-direction: column
	margin-top: 2.1875rem

	&:not(:first-child)
		@media (min-width: common.$break30) and (max-width: common.$break48 - .0625rem)
			grid-column: 2

.Logos
	@media not all and (min-width: common.$break30)
		flex-direction: row-reverse
		justify-content: flex-end
		// &:nth-child(1)
		// 	margin-left: 1rem

.Logo
	&:nth-child(1)
		margin-left: 1rem
		@media (min-width: common.$break30)
			margin-left: 0.5rem

.Title
	+common.subtitle
	margin: 0 0 1.125rem
	max-width: calc(100vw - 2 * var(--global-horizontal-spacing))
	overflow: hidden
	text-overflow: ellipsis

.Phone
	margin-bottom: 0.25rem

.Link
	+common.text
	color: common.$red
	margin-bottom: .5625rem
	padding-right: 1rem

.Text
	+common.text
	margin-top: 0

.Copyright
	color: common.$grey
	font-weight: 700
	font-size: 12px
	line-height: 24px
	margin: 4.375rem 0 1.375rem
	@media (min-width: common.$break62)
		margin: 8.75rem 0 2.8125rem
