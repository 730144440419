@use 'common'

.carousel
	display: flex

.citationList
	position: relative
	display: flex
	align-items: center
	flex-wrap: nowrap
	width: 100%
	overflow: hidden
	overflow: scroll
	scroll-snap-type: x mandatory
	scroll-behavior: smooth
	scrollbar-width: none

.citationWrapper
	display: flex
	justify-content: center
	align-items: center
	flex-shrink: 0
	width: 100%
	scroll-snap-align: start

.citationBox
	display: flex
	flex-direction: column
	width: 80%
	text-align: center
	align-items: center

	@media (min-width: common.$break48)
		width: 50%

.title
	font-size: 1.5em
	margin: 0.75em 0

	@media (min-width: common.$break48)
		font-size: 2em
		margin: 1em 0

.imageBox
	display: flex
	justify-content: center
	align-items: center
	width: 3rem
	height: 3rem
	overflow: hidden

	@media (min-width: common.$break48)

.image
	object-fit: cover
	width: 100%
	height: 100%

.text
	color: common.$red
	font-size: 1em
	margin: 0.75em 0

	@media (min-width: common.$break48)
		font-size: 1.25rem
		margin: 1em 0

.nextButton, .previousButton
	align-self: center
	height: fit-content
	background-color: transparent
	display: flex
	font-size: 1.5em
	cursor: pointer
	align-items: center
	justify-content: center
	cursor: pointer
	border: 2px solid common.$red
	border-radius: 100%
	z-index: 4
	background: white
	flex: 0 0 auto
	width: 76px
	height: 76px

	@media (min-width: common.$break48)
		font-size: 1.75em

.nextButton
	transform: rotate(180deg)

.hidden
	visibility: hidden
