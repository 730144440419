@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions

	&-bridge
		+normalizeSize(227, 57)
	&-button
		+normalizeSize(33, 22)
	&-cross
		+normalizeSize(30, 30)
	&-file
		+normalizeSize(45, 70)
	&-go
		+normalizeSize(512, 512)
	&-hamburger
		+normalizeSize(30, 30)
	&-heart
		+normalizeSize(127, 185)
	&-line
		+normalizeSize(832, 61)
