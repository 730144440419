@use 'common'

.wrapper
	position: relative
	overflow: hidden
	max-width: 90%
	margin-inline: auto

.wrapperOut
	padding: 4rem 0
	z-index: 3
	position: relative
	// &::before
	// 	content: ''
	// 	position: absolute
	// 	top: 0
	// 	z-index: 3
	// 	left: 0
	// 	width: 100px
	// 	height: 100%
	// 	background: linear-gradient(to right, common.$background-color 0%, transparent 100%)
	// 	@media (min-width: common.$break48)
	// 		width: 300px

	// &::after
	// 	content: ''
	// 	position: absolute
	// 	top: 0
	// 	z-index: 3
	// 	right: 0
	// 	width: 100px
	// 	height: 100%
	// 	background: linear-gradient(to left, common.$background-color 0%, transparent 100%)
	// 	@media (min-width: common.$break48)
	// 		width: 300px

.carousel
	display: flex
	margin: 2em 0

	@media (min-width: common.$break48)
		margin: 4em 0

.achievmentsCarousel
	position: relative
	display: flex
	align-items: center
	flex-wrap: nowrap
	width: 100%
	overflow: hidden
	overflow: scroll
	scroll-snap-type: x mandatory
	scroll-behavior: smooth
	scrollbar-width: none

.achievmentWrapper
	display: flex
	justify-content: center
	align-items: center
	flex-shrink: 0
	padding: 0 5rem
	width: 100%
	scroll-snap-align: start
	@media (min-width: common.$break62)
		padding: 0

.achievmentBox
	width: 50%
	text-align: center

.title
	color: common.$red
	font-size: 3em

	@media (min-width: common.$break48)
		font-size: 5em
		margin-bottom: 0.5rem

.text
	+common.text

.nextButton, .previousButton
	align-self: center
	height: fit-content
	position: relative
	background-color: transparent
	font-size: 1.5em
	display: flex
	align-items: center
	justify-content: center
	cursor: pointer
	z-index: 4
	border: 2px solid common.$red
	border-radius: 100%
	background: white
	width: 66px
	position: absolute
	height: 66px

	@media (min-width: common.$break48)
		font-size: 1.75em
		height: 76px
		width: 76px

.previousButton
	top: calc(50% - 33px)
	left: 0
	transform: rotate(180deg)
	@media (min-width: common.$break48)
		top: calc(50% - 38px)

.nextButton
	top: calc(50% - 33px)
	right: 0
	@media (min-width: common.$break48)
		top: calc(50% - 38px)

.hidden
	visibility: hidden

.is_low
	opacity: 0.5
	cursor: not-allowed
