@use 'common'

.Button
	display: flex
	.Center
		justify-content: center

.ButtonSize
	.Text
		+common.subtitle
		@media (min-width: common.$break75)
			font-size: 1.875rem
			line-height: 2.25rem
	.Image
		font-size: 1.3rem
		margin-left: 12px

.Text
	font-weight: 700
	color: common.$red
	margin: 0
	font-size: 1.3rem


.Image
	display: flex
	align-items: center
	margin-left: 6px
	font-size: 1em

.Center
	justify-content: center

.Right
	justify-content: flex-end
