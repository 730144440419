@use '../vars'

=subtitle
	font-weight: 700
	font-size: 1.25rem
	line-height: 1.5rem
	@media (min-width: vars.$break62)
		font-size: 1.5625rem
		line-height: 1.9375rem
	@media (min-width: vars.$break75)
		font-size: 1.875rem
		line-height: 2.25rem
