@use 'common'

.GenericPage
	display: flex
	flex-direction: column

.Cover
	display: flex
	justify-content: space-between
	margin: 2.5rem 0 4.375rem
	@media (min-width: common.$break48)
		margin: 7.5rem 0 5rem

.LeadParagraph
	+common.title
	padding-right: 1rem
	em
		font-style: normal
		color: common.$red
	@media (min-width: common.$break48)
		padding-right: 8rem
		max-width: 63rem

.Image
	display: none
	@media (min-width: common.$break48)
		display: flex
		align-items: flex-end
		min-width: 20%
		margin-right: 2rem

.Programs
	display: flex
	flex-wrap: wrap
	@media (min-width: common.$break48)
		gap: 1.25rem
	@media (min-width: common.$break62)
		gap: 2.5rem
