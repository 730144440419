@use 'common'

.ProgramTile
	width: 100%
	margin-bottom: 5rem
	@media (min-width: common.$break48)
		width: calc(50% - 0.625rem)
	@media (min-width: common.$break62)
		width: calc(50% - 1.25rem)

	&:nth-last-child(1)
		margin-bottom: 2.5rem

.TextList
	padding-left: 1.5rem

.Text
	color: common.$grey
	font-weight: 700
	font-size: 16px
	line-height: 30px

.LinkText
	font-size: 1.875rem
	line-height: 2.25rem
	color: common.$red
	font-weight: 700
	margin: 0

.Heading
	font-size: 2em
	margin: 0.5em 0 0.2em

	&:hover
		color: common.$red
