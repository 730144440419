@use 'common'
@use 'sass:math'

.widgetBox
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	width: 100%
	height: 100%

.widget
	width: 100%
	height: 405px
	max-width: 53rem
	margin: 1.5rem 0
	border: 1px solid common.$red
