@use 'common'

.newsAndStory
	display: flex
	flex-direction: column

.header
	display: flex
	flex-direction: column
	margin-top: 3rem

.title
	+common.title
	max-width: 53rem
	margin: 1.5rem 0

.imageBox
	width: 100%
	max-width: 53rem

.perex
	max-width: 53rem
	font-size: 1.125rem
	line-height: 1.875rem
	font-weight: 700

	@media (min-width: common.$break48)
		font-size: 1.5rem

.content
	max-width: 55rem
	margin-top: 1rem

