@use 'common'

$-gap: 7rem

.wrapper
	position: relative

.aside
	display: flex
	justify-content: center
	margin-top: -9.375rem
	margin-bottom: 6.25rem

	@media (min-width: 81.25rem)
		margin: 0
		position: absolute
		top: 0
		right: 0
		padding-left: var(--global-horizontal-spacing)
		width: 21.875rem

	@media (min-width: common.$contentWidth-wide)
		left: calc(50vw + var(--content-width-wide) / 2 - 21.875rem)
		right: auto

.content
	> *:last-child
		&.section.is_background_white,
		&:not(.section)
			margin-bottom: $-gap

.section

	&.is_background_white + &,
	& + &.is_background_white,
	*:not(.section) + &,
	& + *:not(.section)
		margin-top: $-gap

	&:not(.is_background_white)
		&:not(.is_reference_banner)
			padding-top: $-gap
			padding-bottom: $-gap

.notImplemented
	border: 1px solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
