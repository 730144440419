@use 'common'

.Wrapper
	position: relative
	margin: 10rem 0

.Image
	position: absolute
	inset: 0

.Banner
	display: flex
	flex-direction: column
	position: relative

	max-width: 62.25rem
	margin: auto
	justify-content: center

.Title
	+common.title

	color: #ffffff
	margin: 3rem 2rem 2rem
	@media (min-width: common.$break48)
		margin: 4rem 0 3rem 2.5rem
	@media (min-width: common.$break75)
		margin: 7rem 0 4rem 4.25rem

.Box
	display: flex
	flex-direction: column
	background-color: common.$background-color
	margin: 3rem 1.5rem
	padding: 2rem
	@media (min-width: common.$break48)
		flex-direction: row
		padding: 4.6875rem 6.25rem 5.3125rem 4.0625rem

.Text
	+common.subtitle
	margin: 0
	@media (min-width: common.$break48)
		padding-right: 2rem
		max-width: 23.875rem

.Bridge
	font-size: 3.5625rem
	margin-top: 2rem
	display: flex
	justify-content: flex-end
	width: 100%
	@media (min-width: common.$break48)
		margin-top: 1rem
		font-size: 4rem
	@media (min-width: common.$break62)
		font-size: 5rem
