@use 'common'
@use 'sass:math'

.YoutubeVideo
	position: relative
	aspect-ratio: 16/9
	overflow: hidden
	max-width: 57rem

	& iframe
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
