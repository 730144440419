@use 'common'

.wrapper
	a
		font-weight: 600
		color: common.$red

	h1,
	h2,
	h3,
	h4
		max-width: 60rem
		@media not all and (min-width: common.$break48)
			br
				display: none
	h4
		max-width: 53rem

	h2
		+common.title

	h3
		+common.subtitle

	em
		font-style: normal
		color: common.$red

	ul,
	ol
		max-width: 53rem

	ol
		width: fit-content
		counter-reset: item
		list-style-type: none
		padding: 0
		li
			+common.text
			width: fit-content
			padding: 0.75rem 0
			text-align: start

			&::before
				content: counter(item) " — "
				counter-increment: item

	ol[data-contember-align="center"]
		margin: auto
		li
			+common.subtitle

	p
		max-width: 53rem
		+common.text
		min-height: 1rem

		@media not all and (min-width: common.$break48)
			br
				display: none

	[data-contember-align="center"]
		text-align: center
		margin: auto
	[data-contember-align="end"]
		text-align: end
