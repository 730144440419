@use 'common'
@use 'sass:math'

.PrincipleList
	display: flex
	flex-wrap: wrap

.PrincipleWrapper
	display: flex
	flex-direction: column
	align-items: center
	margin: 2rem 0
	width: 100%
	padding: 0 1rem
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
	@media (min-width: common.$break62)
		width: 25%

.Image
	max-width: 3.75rem
	max-height: 3.75rem

.Title
	+common.subtitle
	margin: 0
	text-align: center

.Text
	+common.text
	text-align: center
