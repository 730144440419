@use 'common'
@use 'sass:math'

.Line

	font-size: 1.25rem
	margin: 3rem 0
	@media (min-width: common.$break48)
		margin: 4rem 0
		font-size: 2.5625rem
	@media (min-width: common.$break75)
		margin: 5rem 0
		font-size: 3.8125rem
